.headerText {
  color: #10111c;
  font-family: Nobile;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0.65px;
  line-height: 30px;
}
.subTitleStyle {
  opacity: 0.4;
  color: #4f5056;
  font-family: 'OpenSans-regular';
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
}
