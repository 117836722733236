@import "../../common-styles/fonts.scss";
@import "../../common-styles/colors.scss";

.contributor-card {
  box-sizing: border-box;
  height: 108px;
  width: 343px;
  border: 1px solid #f0eeee;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.04);
  padding-top: 20px;
  padding-left: 24px;
  padding-bottom: 22px;
  margin-bottom: 16px;
  margin-top: 2px;
  margin-right: 13px;

  &-header {
    color: $BLACK;
    font-family: $OPEN_SANS_SEMI_BOLD;
    font-size: $FOURTEEN;
    font-weight: 600;
    line-height: 18px;
    padding-bottom: 16px;
  }
  &-price {
    color: $BLACK;
    font-family: $CIRCULAR_STD;
    font-size: $EIGHTEEN;
    letter-spacing: 0.45px;
    line-height: 14px;
    padding-bottom: 8px;
  }
  &-contributor-count {
    color: #4f5056;
    font-family: $OPEN_SANS;
    font-size: $TEN;
    line-height: 10px;
  }
}

.winner-card {
  padding-left: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 2px;
  box-sizing: border-box;
  height: 108px;
  width: 343px;
  border: 1px solid #f0eeee;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.04);
  .winner-card-body {
    display: flex;
    align-items: center;
    &-pic {
      height: 34px;
      width: 34px;
      border-radius: 50%;
    }
    &-header {
      opacity: 0.4;
      color: $BLACK;
      font-family: $OPEN_SANS_SEMI_BOLD;
      font-size: $TEN;
      letter-spacing: 0.42px;
      line-height: 10px;
      padding-left: 13px;
      padding-bottom: 8px;
    }
    &-name {
      color: $BLACK;
      font-family: $CIRCULAR_STD;
      font-size: $EIGHTEEN;
      letter-spacing: 0.45px;
      line-height: 14px;
      padding-left: 13px;
    }
  }
}
