@import "../../common-styles/colors.scss";
@import "../../common-styles/fonts.scss";

.notes-listing {
  padding: 33px 0px 20px 42px;
  .notes-header {
    display: flex;
    flex-direction: row;
    &-day {
      opacity: 0.3;
      color: $BLACK;
      font-family: $OPEN_SANS_SEMI_BOLD;
      font-size: $TEN;
      letter-spacing: 0.42px;
      line-height: 10px;
      margin-right: 8px;
    }

    &-time {
      opacity: 0.3;
      color: $BLACK;
      font-family: $OPEN_SANS_SEMI_BOLD;
      font-size: $TEN;
      letter-spacing: 0.42px;
      line-height: 10px;
    }
    .dot-container {
      width: 15px;
      display: flex;
      align-items: center;
      .dot {
        height: 5px;
        width: 5px;
        background-color: #7c7c7c;
        border-radius: 50%;
        display: flex;
        align-items: center;
      }
    }
  }

  .notes-description {
    margin: 8px 0px 8px 0px;
    width: 359px;
    color: #4f5056;
    font-family: $OPEN_SANS;
    font-size: $TWELVE;
    line-height: 15px;
  }

  .notes-footer {
    margin-bottom: 23.5px;
    display: flex;
    align-items: center;
    &-name {
      color: #4f5056;
      font-family: $OPEN_SANS_SEMI_BOLD;
      font-size: $TWELVE;
      line-height: 15px;
      margin-left: 4px;
    }
  }
}
