@import "../../common-styles/fonts.scss";
@import "../../common-styles/colors.scss";

.settings-section {
  &-heading {
    color: $BLACK;
    font-family: $CIRCULAR_STD;
    font-size: 18px;
    letter-spacing: 0.45px;
    line-height: 14px;
    margin-bottom: 34px;
  }
  &-title {
    color: $BLACK;
    font-family: $OPEN_SANS_SEMI_BOLD;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom:2px;
  }
  &-description {
    width: 409px;
    color: #4f5056;
    font-family: $OPEN_SANS;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 24px;
  }
  &-input-label {
    opacity: 0.4;
    color: $BLACK;
    font-family: $OPEN_SANS_SEMI_BOLD;
    font-size: 10px;
    letter-spacing: 0.42px;
    line-height: 10px;
    margin-bottom: 4px;
  }
  &-input input{
    height: 40px;
    width: 400px;
    border-radius: 4px;
    background-color: #f9f9f9;
    margin-bottom: 35px;
    border:none;
    outline: none;
    padding-left: 10px;
  }
  &-input input::-webkit-inner-spin-button
  {
    appearance: none;
  }
  &-buttons{
    display: flex;
  }
  .disabled{
    pointer-events: none;
    opacity:0.7;
  }
  .save-button{
    margin-right:24px;
  }
}
