.tags {
  display: inline-block;
  padding: 4px 10px;
  border-radius: 17px;
  background-color: #EFF5F7;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.04);
  color: #4F5056;
  font-family: 'OpenSans-Regular';
  font-size: 11px;
  line-height: 15px;
  margin-right: 8px;
  margin-bottom:2px;

  .crossImg {
    margin-left: 5px;
    cursor: pointer;
  }
}