.mar-10 {
  margin-left: 5px;
  margin-right: 5px;
}

.CTAWrapper.draw-raffle-button-details {
  width: 150px !important;
  height: 40px;
  display: flex;
  margin-right:10px;

  .InnerShadow{
    box-shadow: none;
  }
}
