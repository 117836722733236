@import "../../common-styles/colors.scss";
@import "../../common-styles/fonts.scss";

@mixin header-font {
  color: $BLACK;
  font-family: $OPEN_SANS_SEMI_BOLD;
  font-size: $FOURTEEN;
  line-height: 18px;
}

@mixin listing-amount-is {
  color: #4f5056;
  font-family: $OPEN_SANS;
  font-size: $TWELVE;
  line-height: 15px;
}

.participant-listing {
  padding: 25px 39px 0px 25px;

  .participant-header-container {
    margin-bottom: 24px;
  }
  &-total-count {
    box-sizing: border-box;
    height: 58px;
    border: 1px solid #f0eeee;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.04);
    display: flex;
    align-items: center;
    padding-left: 28px;

    &-text {
      margin-right: calc(20%);
      @include header-font;
    }
    &-amount {
      @include header-font;
    }
  }

  &-body {
    .participant-list-item {
      display: flex;
      padding-left:55px;
      align-items: center;
      height: 50px;
      width: 90%;
      justify-content:  start;
      .contributor-tag {
        margin-left: 4px;
      }
      .participant-name {
        color: #4f5056;
        font-family: $OPEN_SANS_SEMI_BOLD;
        font-size: $TWELVE;
        line-height: 15px;
        width:210px;
      }
      .participant-is {
        @include listing-amount-is;
        width:210px;
      }
      .participant-amount {
        @include listing-amount-is;
        margin-right:20px;
      }
    }
    .divider-line {
      border-bottom: 1px solid #e0eaee;
    }
    .resync-button{
      border:none;
      padding: 5px;
      border-radius:3px;
      color:$CTA;
      font-family: $OPEN_SANS_BOLD;
      font-size: small;
      background-color: white;
    }
  }
}