@import "../../common-styles/detailCommonStyles.scss";
@import "../../common-styles/colors.scss";
@import "../../common-styles/fonts.scss";

.edit-form {
    width: 400px;
}

.edit-form-loading {
    display: flex;
    .edit-form-loading-txt {
        margin: auto;
        margin-top: 60%;
        color: #10111C;
        font-family: "Circular-std-bold";
        font-size: 18px;
    }
}

.edit-form-error {
    display: flex;
    .edit-form-error-txt {
        margin: auto;
        margin-top: 60%;
        color: red;
        font-family: "Circular-std-bold";
        font-size: 18px;
    }
    .cancel-button {
        margin-top: 70%;
    }
}

.edit-form-container {
     
    // Date and time picker SCSS
    .MuiInput-underline:before {
        display: none;
    }
    .MuiInput-underline:after {
        display: none;
    }
    #raffle-time-picker-helper-text {
        display: none;
    }
    #raffle-date-picker-helper-text {
        display: none;
    }
    .edit-form-date-input-error {
        border: 1px solid #dda7a7 !important;
        background: #fff8f8 !important;
        input {
            background: #fff8f8 !important;
        }
    }
    .edit-form-time-input-error {
        border: 1px solid #dda7a7 !important;
        background: #fff8f8 !important;
        input {
            background: #fff8f8 !important;
        }
    }
    .edit-form-date-input {
       margin-right: 9px;
       background-color: #f9f9f9;
       input {
            border-radius: 4px;
            border: none;
            padding-left: 16px;
            height: 40px;
            background-color:#f9f9f9;
            box-sizing: border-box;
            font-family: $OPEN_SANS;
            font-size: $TWELVE;
            letter-spacing: 0;
            line-height: 15px;
            outline: none;
       }

       .MuiSvgIcon-root {
          color: #41bbe5;
          width: 15px;
          height: 15px;
       }
    }

    // Select box SCSS 
    .css-bgvzuu-indicatorSeparator {
        display: none;
    }
    .css-16pqwjk-indicatorContainer {
        color: #1CA5D5;
    }
    .css-kj6f9i-menu {
        background-color: #F9F9F9;
    }

    .edit-form-heading {
        .edit-lunch-details {
            color: #10111C;
            font-family: "Circular-std-bold";
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0.45px;
            line-height: 14px;
        }
        .go-back-heading {
            height: 10px;
            color: #1CA5D5;
            font-family: "OpenSans-regular";
            font-size: 10px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 10px;
            margin-left: 4px;
        }
        .go-back-image{
            cursor: pointer;
        }
    }

    .edit-form-sub-heading {
        height: 14px;
        color: #10111C;
        font-family: "Circular-std-bold";
        font-weight: bold;
        letter-spacing: 0.45px;
        line-height: 14px;     
        margin-top: 24px;
        margin-bottom: 10px;   
    }

    .edit-form-contact-heading {
        margin-top: 41px;
        span {
            height: 18px;
            color: #10111C;
            font-family: OpenSans-regular;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 18px;
            margin-left: 8px;
        }
    }

    .edit-save-sub-btn {
        margin-top: 34px;
        button {
            width: auto;
        }
        .disabled {
            opacity: 0.6;
            pointer-events: none;
        }
    }

    .edit-form-btn-container {
        margin-top: 61px;
        .btn-ctrl{
            display: flex;
            .disabled {
                opacity: 0.6;
                pointer-events: none;
            }
            svg {
                margin-right: 4px;
            }
        }
        .errorText {
            color: #cd4242;
            font-family: $OPEN_SANS;
            font-size: 12px;
            line-height: 12px;
            margin-top: 7px;            
        }
    }

    .cancel-button {
        padding-left: 13px;
    }
    
    // Customize radio btns SCSS
    .edit-form-radio-control-container {
        margin-top: 30px;
        .errorText {
            color: #cd4242;
            font-family: $OPEN_SANS;
            font-size: 12px;
            line-height: 12px;
            margin-top: 7px;
        }
        .edit-form-radio-heading {
            height: 18px;
            color: #10111C;
            font-family: "OpenSans-regular";
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 18px;
            margin-bottom: 12px;
        }
        .edit-form-radio-controls {
            display: flex;
            .edit-form-radio-control {
                margin-right: 20px;
                position: relative;
                .edit-form-radio-label {
                    height: 16px;
                    color: #4F5056;
                    font-family: "OpenSans-regular";
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 16px;
                    margin-right: 7px;    
                    padding-bottom: 10px;     
                }
                .edit-form-radio-input {
                    height: 17px;
                    width: 17px;
                    border-radius: 8px;
                    background-color: #65BE6A;
                }
            }
            [type="radio"]:checked,
            [type="radio"]:not(:checked) {
                position: absolute;
                top: 6px;
                z-index: 9999;
                opacity: 0;
            }
            [type="radio"]:checked + label,
            [type="radio"]:not(:checked) + label
            {
                position: relative;
                padding-left: 26px;
                display: inline-block;
            }
            [type="radio"]:checked + label:before,
            [type="radio"]:not(:checked) + label:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 16px;
                height: 16px;
                border: 1px solid #777777;
                border-radius: 8px;
                background-color: #FFFFFF;
            }
            [type="radio"]:checked + label:after,
            [type="radio"]:not(:checked) + label:after {
                content: '';
                width: 10px;
                height: 10px;
                background: #65BE6A;
                position: absolute;
                top: 3px;
                left: 3px;
                border-radius: 8px;
                -webkit-transition: all 0.2s ease;
                transition: all 0.2s ease;
            }
            [type="radio"]:not(:checked) + label:after {
                opacity: 0;
                -webkit-transform: scale(0);
                transform: scale(0);
            }
            [type="radio"]:checked + label:after {
                opacity: 1;
                -webkit-transform: scale(1);
                transform: scale(1);
            }
        }
    }

    .edit-form-control-container {
        margin-top: 16px;
        .edit-lunch-form-label {
            opacity: 0.4;
            color: #10111C;
            font-family: $OPEN_SANS;
            font-size: 10px;
            font-weight: 600;
            letter-spacing: 0.42px;
            line-height: 10px;
            margin-bottom: 4px;
        }

        .errorText {
            color: #cd4242;
            font-family: $OPEN_SANS;
            font-size: 12px;
            line-height: 12px;
            margin-top: 7px;
        }

        // Customized date/time picker SCSS
        .edit-lunch-form-datetime-control {
            display: flex;
            justify-content: space-between;
            
            .MuiFormControl-root {
                height: 40px;
            }
            .datetimeInput {
                border-radius: 4px;
                border: none;
                padding-left: 16px;
                height: 40px;
                background-color:#f9f9f9;
                box-sizing: border-box;
                font-family: $OPEN_SANS;
                font-size: $TWELVE;
                letter-spacing: 0;
                line-height: 15px;
                flex-basis: 48.5%;
                padding-right: 10px;
                outline: none;
            }
            .datetimeInput::placeholder {
                opacity: 0.2;
                color: #555766;
                font-family: $OPEN_SANS;
                font-size: $TWELVE;
                line-height: 18px;
            }
        }

        // Raffle Amount Inputs SCSS
        .edit-lunch-form-raffle-amt-control {
            display: flex;
            .raffle-amount-error {
                border: 1px solid #dda7a7 !important;
                span {
                    background: #fff8f8 !important;
                }
                .raffle-amount-text-input {
                    background: #fff8f8 !important;
                }
            }
            .raffle-amount-text-input-pre {
                width: 94px;
                margin-right: 6px;
                border-radius: 4px;
                border: none;
                display: flex;
                span {
                    padding-left: 16px;
                    background-color:#f9f9f9;
                    box-sizing: border-box;
                    font-family: $OPEN_SANS;
                    font-size: $TWELVE;
                    letter-spacing: 0;
                    line-height: 15px;
                    padding-top: 13px;
                }

                .raffle-amount-text-input::-webkit-outer-spin-button,
                .raffle-amount-text-input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
                
                .raffle-amount-text-input {
                    border: none;
                    height: 40px;
                    background-color:#f9f9f9;
                    box-sizing: border-box;
                    font-family: $OPEN_SANS;
                    font-size: $TWELVE;
                    letter-spacing: 0;
                    line-height: 15px;
                    width: 68px;
                    outline: none;
                    padding-right: 10px;
                    -moz-appearance: textfield;
                }

                .raffle-amount-text-input::placeholder {
                    opacity: 0.2;
                    color: #555766;
                    font-family: $OPEN_SANS;
                    font-size: $TWELVE;
                    line-height: 18px;
                }
           }
           
           .raffle-amount-text-input-pre:last-child {
                margin-right: 0px;
            }
        }
        .edit-lunch-form-control {
            
            // Charity / Celebrity Cards SCSS
            .cards {
                display: flex;
                flex-direction: row;
                margin-top: 5px;
                cursor: pointer;
        
                .charity-card {
                  margin-right: 9px;
                  box-shadow: 0 9px 17px -4px rgba(218,69,60,0.42);
                  border-radius: 10px;
                  height: inherit;
                  padding-bottom: 17px;
                  flex: 1;
                }
                .celebrity-card {
                  box-shadow: 0 9px 17px -4px rgba(255,151,56,0.47);
                  border-radius: 10px;
                  padding-bottom: 17px;
                  height: inherit;
                  flex: 1;
                  &-image {
                    margin-left: -10px;
                  }
          
                  .name {
                    height: 34px;
                    width: calc(167px - 20%);
                    margin-top: -2px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
            }

            .textInput {
                border-radius: 4px;
                border: none;
                padding-left: 16px;
                height: 40px;
                background-color:#f9f9f9;
                box-sizing: border-box;
                font-family: $OPEN_SANS;
                font-size: $TWELVE;
                letter-spacing: 0;
                line-height: 15px;
                width: 400px;
                outline: none;
            }
            .textInput::placeholder {
                opacity: 0.2;
                color: #555766;
                font-family: $OPEN_SANS;
                font-size: $TWELVE;
                line-height: 18px;
            }
    
            .text-area-input {
                height: 100px;
                width: 400px;
                border-radius: 4px;
                background-color: #F9F9F9;
                border: none;
                resize: none;
                overflow-y: scroll;
                outline: none;
                padding: 11px 51px 16px 11px;
                font-family: $OPEN_SANS;
                font-size: $TWELVE;
                letter-spacing: 0;
                line-height: 18px;
            }
        }
    }
    
    
}
