.wrapperForgot {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .signInText {
    margin: 24px 0;
    font-family: 'OpenSans-regular';
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    text-align: center;
    color: #4f5056;
    position: relative;
    display: block;
    .signInTextColor {
      color: #1ca5d5;
    }

    .loaderDiv {
      position: absolute;
      bottom: top;
      top: -3px;
      right: -32px;
    }
  }
  .marginTopCode {
    margin-top: 40px;
  }
  .buttonStyle {
    margin-top: 16px;
    height: 44px;
    width: 327px;
  }
  .marginTop {
    margin-top: 24px;
  }
}
