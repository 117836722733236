.list {
  border: 1px solid #e6f3f7;
  font-family: "OpenSans-regular";

  &-header {
    font-size: 12px;
    color: #10111c;
    display: flex;
    background-color: #e6f3f7;
    height: 43px;
    align-items: center;

    .list-column {
      // flex-grow: 1;
      width: 100%;
      font-weight: bold;
      line-height: 15px;
      justify-content: space-evenly;
      line-height: 18px;
    }
    .list-column:first-child{
      padding-left: 32px;
    }
    .list-column-id {
      width: 70%;
      justify-content: center;
      font-weight: bold;
    }

    .list-column.username {
      width: 98%;
    }

    .list-column.lunchid {
      width: 96%;
    }

    .list-column.lunchname {
      width: 98%;
    }

    .list-column.email {
      width: 97%;
    }

    .list-column.lunchdate {
      width: 97%;
    }

    .list-column.lunchtime {
      width: 95%;
    }

    .list-column-id {
      padding-left: 32px;
    }

    .action {
      visibility: hidden;
    }
  }

  &-body {
    .list-row {
      font-size: 12px;
      line-height: 15px;
      color: #4f5056;
      display: flex;
      // background-color: #ffffff;
      height: 60px;
      align-items: center;

      .list-column {
        width: 100%;
        justify-content: space-evenly;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .list-column.statusLunches {
        margin-left: 20px;
      }

      .list-column:first-child {
        padding-left: 32px;

        a {
          color: #4f5056 !important;
        }

        a:hover {
          text-decoration: none;
          cursor: unset;
        }
      }

      .list-column.lunchid {
        font-weight: inherit;
      }

      .dot {
        height: 7px;
        width: 7px;
        background-color: #65be6a;
        box-shadow: 0 0 8px 0 rgba(109, 255, 116, 0.4);
        border-radius: 50%;
        display: inline-block;
        margin-right: 10px;
      }

      .active {
        background-color: #65be6a;
      }

      .inactive {
        background-color: #FF7D7D;
      }

      .action {
        display: flex;
        justify-content: flex-end;
        padding-right: 24px;
        cursor: pointer;
      }
    }

    // .list-row.oddEven:nth-child(even) {
    //     background-color: red;
    // }
  }

  &-loading {
    font-size: 12px;
    line-height: 15px;
    color: #4f5056;
    display: flex;
    background-color: #ffffff;
    height: 43px;
    align-items: center;
    justify-content: center;
  }

  &-action {
    width: 154px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 1px 17px 0 rgba(0, 0, 0, 0.09);
    display: none;
    position: absolute;
  }
}

.cursorPointer {
  cursor: pointer;
}

.oddEven:nth-child(even) {
  background-color: #f9f9f9;
}

.cursorPointer:hover {
  background-color: rgba(0, 0, 0, 0.09);
}
