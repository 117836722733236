@import "../common-styles/fonts.scss";

.detail-container {
  margin-left: 40px;
  .header {
    .title {
      font-size: 18px;
      letter-spacing: 0.45px;
      line-height: 14px;
      color: #10111c;
      font-family: $CIRCULAR_STD;
      display: inline-block;
      max-width: 60vw;
    }
    .dot {
      height: 7px;
      width: 7px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 4px;
    }
    .status {
      height: 15px;
      width: 100px;
      color: #4f5056;
      font-family: $OPEN_SANS;
      font-size: $TWELVE;
      line-height: 15px;
    }
  }
  .tabs {
    margin: 24px 0px 12px 0px;

    .tab-label{
      margin-bottom: 4px !important;
    }
  }
  .main-section {
    box-sizing: border-box;
    margin-bottom: 20px;
    width: calc(100% - 40px) /*1360px*/; // this should be according to the window
    border: 1px solid #e0eaee;
    background-color: #ffffff;
    box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.04);
  }
  .details {
    padding: 33px 0px 0px 42px;
  }
}
