@import "../../common-styles/detailCommonStyles.scss";
@import "../../common-styles/colors.scss";
@import "../../common-styles/fonts.scss";

.detail-container {
  .header {
    display: flex;
    p {
      margin: 0 !important;
      padding: 0 !important;
    }
    // height: 19px;
    .dot {
      margin-left: 20px;
      margin-top: 4px;
    }
  }
  .tab-s {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px;
    .tabs {
      margin: 21px 0px 12px 0px;
    }
    .tab {
      margin-bottom: 0px;
      .tab-border.active {
        width: 29px !important;
      }
    }
    .action-dropdown {
      margin-right: 40px;
      display: flex;
      align-items: flex-start;
      .loading {
        margin-right: 10px;
      }
      .action-mark-completed-button {
        height: 40px;
        width: 145px;
        display: flex;
        margin-right: 12px;

        .InnerShadow {
          box-shadow: none;
          .CTAText {
            font-size: 14px !important;
            color: #ffffff;
            font-family: $OPEN_SANS_SEMI_BOLD;
            line-height: 16px;
            margin: 0;
            font-weight: normal;
          }
        }
      }
      .add-note-button {
        height: 40px;
        width: 103px;
        display: flex;
        background-color: #edfaff;
        border: 1px solid $CTA;
        margin-right: 12px;
        .InnerShadow {
          box-shadow: none;
          .CTAText {
            font-size: 12px !important;
            color: #1ca5d5;
            font-family: $OPEN_SANS_SEMI_BOLD !important;
            line-height: 15px;
            margin: 0;
            font-weight: normal;
          }
        }
      }
      .no-add-note-margin {
        margin: 0;
      }
      .css-kj6f9i-menu {
        right: 0px;
        top: 33px;
      }
      .css-11unzgr {
        max-height: none;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      }

      .cause-action-dropdown {
        box-sizing: border-box;
        .dropDown__placeholder {
          font-weight: normal !important;
          font-family: $OPEN_SANS_SEMI_BOLD !important;
        }
        .dropDown__menu {
          height: 238px;
          width: 170px;
        }
        .dropDown__menu-list {
          border-radius: 4px;
          background-color: #ffffff;
          //box-shadow: 0 1px 17px 0 rgba(0, 0, 0, 0.09);
          height: 238px;
          width: 170px;
        }
        .css-1hwfws3 {
          padding-left: 14px;
        }
        .dropDown__option {
          height: calc(238 / 5);
        }
        .drop-down-item {
          .drop-down-image {
            height: 12px;
            width: 12px;
          }
          .drop-down-options {
            margin-left: 10px;
            height: 15px;
            width: 85px;
            color: #4f5056;
            font-family: $OPEN_SANS;
            font-size: $TWELVE;
            line-height: 15px;
          }
        }
      }
      .cause-action-dropdown-general {
        box-sizing: border-box;
        .dropDown__placeholder {
          font-weight: normal !important;
          font-family: $OPEN_SANS_SEMI_BOLD !important;
        }
        .dropDown__menu {
          height: 148px;
          width: 170px;
        }
        .dropDown__menu-list {
          border-radius: 4px;
          background-color: #ffffff;
          //box-shadow: 0 1px 17px 0 rgba(0, 0, 0, 0.09);
          height: 148px;
          width: 170px;
        }
        .css-1hwfws3 {
          padding-left: 14px;
        }
        .dropDown__option {
          height: calc(148 / 3) !important;
        }
        .drop-down-item {
          .drop-down-image {
            height: 12px;
            width: 12px;
          }
          .drop-down-options {
            margin-left: 10px;
            height: 15px;
            width: 85px;
            color: #4f5056;
            font-family: $OPEN_SANS;
            font-size: $TWELVE;
            line-height: 15px;
          }
        }
      }
      .cause-action-dropdown-completed {
        box-sizing: border-box;
        .dropDown__placeholder {
          font-weight: normal !important;
          font-family: $OPEN_SANS_SEMI_BOLD !important;
        }
        .css-1hwfws3 {
          padding-left: 14px;
        }
        .dropDown__menu {
          height: 48px;
          width: 170px;
        }
        .dropDown__menu-list {
          border-radius: 4px;
          background-color: #ffffff;
          //box-shadow: 0 1px 17px 0 rgba(0, 0, 0, 0.09);
          height: 48px;
          width: 170px;
        }
        .dropDown__option {
          height: 48;
        }
        .drop-down-item {
          .drop-down-image {
            height: 12px;
            width: 12px;
          }
          .drop-down-options {
            margin-left: 10px;
            height: 15px;
            width: 85px;
            color: #4f5056;
            font-family: $OPEN_SANS;
            font-size: $TWELVE;
            line-height: 15px;
          }
        }
      }
      .dropDownContainer .dropDown__placeholder {
        color: #1ca5d5;
        opacity: 1;
        font-family: $OPEN_SANS;
        font-weight: 600;
      }
      .dropDownContainer .dropDown__control {
        background-color: #edfaff;
      }
    }
  }
  .detail-wrapper {
    padding-bottom: 40px;
  }
  .detail-wrapper-raffle {
    padding-bottom: 40px;
  }
  .charity-detail-wrapper {
    margin-bottom: 40px;
  }
  .dropdown-width {
    height: 40px;
    width: 106px;
  }
  .detail-wrapper,
  .charity-detail-wrapper {
    display: flex;
    flex-direction: row;

    .cause-details {
      height: 10px;
      opacity: 0.4;
      color: $BLACK;
      font-family: $OPEN_SANS_SEMI_BOLD;
      font-size: $TEN;
      letter-spacing: 0.42px;
      line-height: 10px;
    }
    .cause-benefits {
      width: 370px;
    }
    .celebrity-bio {
      width: 515px;
    }
    .cause-details-celebrity-phone-number {
      height: 10px;
      color: $BLACK;
      font-family: $OPEN_SANS_SEMI_BOLD;
      font-size: $TEN;
      letter-spacing: 0.42px;
      line-height: 10px;
      margin-right: 30px;
    }
    .cause-details-celebrity-email {
      height: 10px;
      color: $BLACK;
      font-family: $OPEN_SANS_SEMI_BOLD;
      font-size: $TEN;
      letter-spacing: 0.42px;
      line-height: 10px;
    }
    .cause-details-contact-tobe-sent-to {
      margin-top: 24px;
      &-winner {
        color: $BLACK;
        font-family: $OPEN_SANS_SEMI_BOLD;
        font-size: $FOURTEEN;
        line-height: 18px;
        margin-bottom: 8px;
      }

      .cause-details-primary-contact {
        display: flex;
        .primary-contact-image {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
        .primary-contact {
          height: 16px;
          color: #4f5056;
          font-family: $OPEN_SANS;
          font-size: $TWELVE;
          line-height: 16px;
        }
      }
    }
    .cause-details-url {
      margin-top: 22px;
      font-size: $TEN;
      opacity: 0.4;
      color: $BLACK;
      font-family: $OPEN_SANS_SEMI_BOLD;
      letter-spacing: 0.42px;
      line-height: 10px;
    }
    .cause-detail-margin {
      margin-top: 8px;
      margin-bottom: 22px;
    }
    .cause-detail-margin-list {
      margin-top: 2px;
      margin-bottom: 22px;
    }
    .cause-detail-text-format {
      color: $BLACK;
      font-family: $OPEN_SANS;
      font-size: $TWELVE;
      line-height: 15px;
    }
    .cause-detail-text-format-amount {
      color: #4f5056;
      line-height: 15px;
      font-family: $OPEN_SANS_BOLD;
      font-size: $TWELVE;
    }
    .cause-detail-bio {
      color: $BLACK;
      font-family: $OPEN_SANS;
      font-size: $TWELVE;
      line-height: 19px;
    }
    .bold {
      font-weight: 600;
    }
    .raffle-amount-seperator {
      box-sizing: border-box;
      height: 13px;
      width: 1px;
      color: #979797;
      margin-left: 7.5px;
      margin-right: 7.5px;
    }
    .draw-date {
      color: $GREEN;
      height: 12px;
      color: #19a520;
      font-family: $OPEN_SANS;
      font-size: $TWELVE;
      line-height: 12px;
      margin-top: -18px;
      margin-bottom: 24px;
      span {
        font-weight: bold;
      }
    }
    .cards {
      display: flex;
      flex-direction: row;
      .charity-card {
        margin-right: 8px;
        box-shadow: -3px 10px 5px fade-out($color: #da453c, $amount: 0.9);
        height: inherit;
        padding-bottom: 17px;
      }
      .celebrity-card {
        box-shadow: -3px 10px 5px fade-out($color: #ff9738, $amount: 0.9);
        padding-bottom: 17px;
        height: inherit;
        &-image {
          margin-left: -10px;
        }

        .name {
          height: 34px;
          width: calc(167px - 20%);
          margin-top: -2px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .host-details-card {
      margin-top: 33px;
      margin-left: 70px;
      box-sizing: border-box;
      height: 144px;
      width: 417px;
      border: 1px solid #e0eaee;
      background-color: #ffffff;
      box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.04);
      padding-left: 25px;
      padding-top: 21px;

      .host-details-title {
        opacity: 0.4;
        color: $BLACK;
        font-family: $OPEN_SANS;
        font-size: $TEN;
        font-weight: 600;
        letter-spacing: 0.42px;
        line-height: 10px;
        margin-bottom: 16px;
      }

      .host-name {
        color: $BLACK;
        font-family: $OPEN_SANS;
        font-size: $FOURTEEN;
        font-weight: 600;
        line-height: 18px;
      }

      .host-phone-number-email {
        font-size: $TWELVE;
        font-family: $OPEN_SANS;
        color: $BLACK;
        line-height: 15px;
        margin-top: 8px;

        .separator {
          height: 15px;
          width: 2px;
          color: $GREY;
          // background-color: $GREY;
          margin: 0px 8px 0px 8px;
        }
      }
      .view-more-details {
        margin-top: 24px;
        border: none;
        color: $BLUE;
        font-size: $TWELVE;
        font-family: $OPEN_SANS;
        padding: 0px;
        background-color: white;
      }
    }
  }
}

.add-note-section {
  &-heading {
    height: 14px;
    width: 84px;
    color: #10111c;
    font-family: $CIRCULAR_STD;
    font-size: $EIGHTEEN;
    letter-spacing: 0.45px;
    line-height: 14px;
    margin-bottom: 26px;
  }

  .save-button {
    margin-right: 24px;
  }
  .disabled {
    pointer-events: none;
    background-color: $GREY;
  }

  &-textbox {
    margin-bottom: 33px;

    &-style {
      height: 154px;
      width: 400px;
      border-radius: 4px;
      background-color: #f9f9f9;
      resize: none;
      border: none;
      overflow-y: scroll;
      outline: none;
      padding: 10px 10px 20px 10px;
    }
    &-style::placeholder {
      height: 18px;
      width: 147px;
      opacity: 0.3;
      color: #555766;
      font-family: $OPEN_SANS;
      font-size: $TWELVE;
      line-height: 18px;
    }
  }
}
