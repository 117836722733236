.switchButton {
  width: 169px;
  height: 40px;
  display: flex;
  border-radius: 20px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  &-toggler {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    background: #FFFFFF;
    border-radius: 20px;

    color: #4F5056;;	font-family: "OpenSans-regular";	font-size: 10px;	font-weight: bold;	line-height: 10px;
  }
  &-pointer{

    cursor: pointer;
  }
  &-toggler.active {
    color: #FFFFFF;
    width: 60%;
    background: #65BE6A;
    border-radius: 20px;
  }
}