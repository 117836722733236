@import "../../common-styles/fonts.scss";

.profile-container {
  width: 95%;
  margin: 0px 40px;

  .profile-breadcrumb {
    padding-top: 16px;
    padding-bottom: 16px;

    .profile-breadcrumb-users {
      height: 10px;
      width: 56px;
      color: #1ca5d5;
      font-family: "OpenSans-regular";
      font-size: 10px;
      font-weight: 600;
      line-height: 10px;
      margin-right: 5px;
      cursor: pointer;
    }

    .profile-breadcrumb-dropdown {
      height: 4px;
      width: 7px;
      transform: rotate(90deg);
      margin-right: 5px;
    }

    .profile-breadcrumb-user {
      height: 10px;
      color: #4f5056;
      font-family: "OpenSans-regular";
      font-size: 10px;
      line-height: 10px;
      margin-right: 5px;
    }
  }

  .userprofile {
    box-sizing: border-box;
    border: 1px solid #e0eaee;
    background-color: #ffffff;
    box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.04);
    display: flex;

    &-main {
      margin: 9px;
      background-color: #e6f3f7;
      // flex-grow: 1;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      width: 32%;

      img {
        width: 110px;
        height: 110px;
        display: block;
        border-radius: 50%;
        margin-top: 48px;
        margin-bottom: 16px;
      }

      .name {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;
        height: 14px;
        color: #10111c;
        font-family: $CIRCULAR_STD;
        font-size: $EIGHTEEN;
        letter-spacing: 0.45px;
        line-height: 14px;
      }

      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right:0px;
        padding:20px;
        margin-bottom: 40px;
        height: 15px;
        color: #4f5056;
        font-family: $OPEN_SANS;
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
      }

      .service {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        height: 10px;
        color: #4f5056;
        font-family: "OpenSans-regular";
        font-size: 10px;
        line-height: 10px;
      }

      .add-notes {
        height: 40px;
        width: 109px;
        border: 1px solid #38bae7;
        border-radius: 4px;
        background-color: #edfaff;
        color: #1ca5d5;
        font-family: $OPEN_SANS_SEMI_BOLD;
        font-size: $TWELVE;
        line-height: 15px;
      }

      .contact-details {
        display: flex;
        margin-bottom: 85px;
        min-width: 400px;
        width: 100%;
        justify-content: center;

        .info {
          display: flex;
          align-items: center;

          &-img {
            display: block;
            height: 10px;
            width: 11px;
            margin: 0px;
            border-radius: 0%;
            margin-right: 4px;
          }
          &-img-phone {
            display: block;
            height: 12px;
            width: 12px;
            margin: 0px;
            border-radius: 0%;
            margin-right: 3px;
          }
          &-value {
            display: block;
          }

          height: 15px;
          color: #4f5056;
          font-family: "OpenSans-regular";
          font-size: 12px;
          font-weight: 600;
          line-height: 15px;
        }

        .info:first-child {
          margin-right: 20px;

          .info-img {
            transform: rotate(-10deg);
          }
        }
      }
    }

    @media only screen and (max-width: 1024px) {
      .contact-details {
        display: flex;
        flex-direction: column;
        .info {
          display: flex;
          justify-content: center;
          margin: 12px;
        }
      }
    }

    &-details {
      margin: 49px 56px;
      width: 40%;

      .bio,
      .restaurants,
      .lunch-location,
      .interests {
        margin-top: 24px;
      }

      p {
        margin-bottom: 0px;
      }

      label {
        display: block;
        opacity: 0.4;
        color: #10111c;
        font-family: $OPEN_SANS_SEMI_BOLD;
        font-size: 10px;
        letter-spacing: 0.42px;
        line-height: 10px;
      }

      .user-text {
        color: #10111c;
        font-family: "OpenSans-regular";
        font-size: 12px;
        line-height: 15px;
      }

      .user-tags {
        margin-bottom: 20px;

        .user-tag {
          margin-bottom: 5px;
          margin-right: 10px;
          padding: 7px 5px 7px 5px;
          display: inline-block;
          border-radius: 17px;
          background-color: #eff5f7;
          box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.04);

          .tag-text {
            height: 15px;
            color: #4f5056;
            font-family: "OpenSans-regular";
            font-size: 11px;
            line-height: 15px;
          }
        }
      }
    }
  }
}


.container-margin{
  margin-bottom: 20px;
}