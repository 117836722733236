.CTAWrapper {
  border-radius: 4;
  background-color: #41bbe5;
  flex-direction: 'row';
  justify-content: 'center';
  align-items: center;
  // height: 44px;
  // width: 327px;
  border-radius: 4px;
  background-color: #41bbe5;
  width: 257px;
  cursor: pointer;

  .InnerShadow {
    box-shadow: 0 10px 10px -10px rgba(68, 98, 203, 0.35);
    width: 100%;
    margin: 0 auto;
    height: 44px;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .CTAText {
    color: #ffffff;
    font-family: 'OpenSans-regular';
    font-size: 16px;
    font-weight: 600;
    line-height: 15px;
    margin-right: 5px;
  }
}
