@import './../../../../Assets/Style/variable.scss';

.tab {
  margin: 0px 24px 17px 0px;

  &-label {
    height: 15px;
    color: $grey;
    font-family: "OpenSans-regular";
    font-size: 12px;
    margin-bottom: 5px;
    cursor: pointer;
  }

  &-label.active {
    color: #1CA5D5;
    font-family: "OpenSans-bold";
    font-size: 12px;
    line-height: 15px;
  }

  &-border.active {
    border-bottom: 2px solid $blue;
    width: 20px;
  }
}