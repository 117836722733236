.hosts {
  width: 95%;
  margin: 0px 40px;
  position: relative;

  .navbar {
    padding: 0px;

    .nav-link {
      padding-left: 0px;
    }
  }

  .navBarText {
    font-family: 'OpenSans-regular';
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #4f5056;

    .activeDiv {
      width: 20px;
    }
  }

  .cursorPointer {
    cursor: pointer;
  }

  &-subHeader {
    margin-top: 29px;
    display: flex;
    justify-content: space-between;

    .title {
      align-self: center;
      font-size: 14px;
      font-family: 'Nobile';
    }
  }

  .rightSectionSubHeadr {
    display: flex;
    align-items: center;
    position: relative;

    .iconRight {
      display: inline-block;
      margin: 1rem;
    }
  }

  .errorText {
    font-size: 10px;
    font-family: 'OpenSans-Regular';
    color: red;
    margin-top: 12px;
  }

  .labelBottom {
    margin-bottom: 5px
  }

  .labelTop {
    margin-top: 27px;
    margin-bottom: 5px
  }

  .cursorPointer {
    cursor: pointer;
  }

  .modalNameInput {
    width: 367px;
  }

  .displayFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .width-50 {
    width: 50%;
  }

  .justifyAround {
    justify-content: normal;
  }

  .marginLeftTill {
    margin-left: 120px;
  }

  .tillTextLeft {
    margin-left: 4px;
  }

  .widthSelect {
    width: 79px;
    border-radius: 10px;
    // border: 1px solid red;

  }

  .titleModal {
    font-size: 14px;
    font-family: "OpenSans-bold";
    margin-bottom: 11px;
  }

  .label {
    opacity: 0.4;
    color: #10111C;
    font-family: "OpenSans-semibold";
    font-size: 10px;
    line-height: 18px;
  }

  .actionButtonWrapper {
    display: flex;
    align-items: center;

    // margin-top: 16px;
    .buttonStyleSave {
      height: 40px;
      width: 80px;

      .InnerShadow {
        width: 80px;
        height: 40px;
        box-shadow: 0 10px 10px -10px rgba(68, 98, 203, 0);
      }

      .textStyle {
        font-size: 12px;
        // padding: 0 10px;
      }
    }

    .cancelText {
      font-family: 'OpenSans-regular';
      font-size: 10px;
      line-height: 15px;
      color: #1ca5d5;
      margin-left: 1rem;
    }
  }

  .displayFlex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 16px 0 8px 0;

    .filterName {
      opacity: 0.3;
      color: #10111C;
      font-family: "OpenSans-regular";
      font-size: 10px;
      letter-spacing: 0.42px;
      line-height: 10px;
      margin-right: 8px;
      font-weight: bold;
    }

    .clearAll {
      color: #1CA5D5;
      font-family: "OpenSans-regular";
      font-size: 12px;
      line-height: 15px;
      font-weight: bold
    }
  }

  .marginTopActionButton {
    margin-top: 32px
  }
}