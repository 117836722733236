@import "../../common-styles/detailCommonStyles.scss";
@import "../../common-styles/colors.scss";
@import "../../common-styles/fonts.scss";

.edit-form-input-control-container {
  margin-top: 16px;
  .edit-form-input {
    border-radius: 4px;
    border: none;
    padding-left: 16px;
    height: 40px;
    background-color:#f9f9f9;
    box-sizing: border-box;
    font-family: $OPEN_SANS;
    font-size: $TWELVE;
    letter-spacing: 0;
    line-height: 15px;
    width: 400px;
    outline: none;
  }
  .edit-form-input::placeholder {
    opacity: 0.2;
    color: #555766;
    font-family: $OPEN_SANS;
    font-size: $TWELVE;
    line-height: 18px;
  }
  .edit-form-input-label {
    opacity: 0.4;
    color: #10111C;
    font-family: $OPEN_SANS;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.42px;
    line-height: 10px;
    margin-bottom: 4px;
  }

  .errorText {
    color: #cd4242;
    font-family: $OPEN_SANS;
    font-size: 12px;
    line-height: 12px;
    margin-top: 7px;
  }
  .errorborder {
    border: 1px solid #dda7a7 !important;
    background: #fff8f8 !important;
    .react-phone-number-input__row {
      background: #fff8f8 !important;
      input {
        background: #fff8f8 !important;
      }
    }
  }

  .text-area-input {
    height: 100px;
    width: 400px;
    border-radius: 4px;
    background-color: #F9F9F9;
    border: none;
    resize: none;
    overflow-y: scroll;
    outline: none;
    padding: 11px 51px 16px 11px;
    font-family: $OPEN_SANS;
    font-size: $TWELVE;
    letter-spacing: 0;
    line-height: 18px;    
  }

  .edit-form-input::-webkit-outer-spin-button,
  .edit-form-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .edit-form-input {
    -moz-appearance: textfield;
  }

  .react-phone-number-input__row {
    height: 40px;
    border-radius: 4px;
    background-color: #F9F9F9;
    padding-left: 16px;
    input {
        background-color: #F9F9F9;
        border: none;
        height: 18px;
        width: 94px;
        color: #555766;
        font-family: $OPEN_SANS;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
    }
    svg {
        vertical-align: top;
    }
    img {
        vertical-align: top;
    }
}

}
