.lunch-container {
  width: 95%;
  margin: 0px 40px;

  .title {
    display: inline;
    margin-right: 20px;
    color: #10111C;
    font-family: Nobile;
    font-size: 16px;
    /* font-weight: bold; */
    letter-spacing: 0.4px;
    line-height: 14px;
  }

  .dot {
    height: 7px;
    width: 7px;
    border-radius: 50%;
    display: inline-block;
    margin-left:5px;
    margin-right: 4px;
    margin-top: -3px
  }

  .lunch-header {
    margin-bottom: 10px;
  }

  .lunch-status {
    height: 15px;
    color: #4F5056;
    font-family: "OpenSans-regular";
    font-size: 12px;
    line-height: 15px;
  }

  .lunchDetail {
    box-sizing: border-box;
    border: 1px solid #E0EAEE;
    background-color: #FFFFFF;
    box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.04);

    &-overview {
      margin: 33px 42px;
      display: flex;

      .lunchDetail-label {
        margin: 24px 0 8px 0;


        a {
          display: block
        }

        label {
          opacity: 0.4;
          color: #10111C;
          font-family: "OpenSans-semibold";
          font-size: 10px;
          letter-spacing: 0.42px;
          line-height: 10px;

        }

        .lunchDetail-text,
        span {
          color: #10111C;
          font-family: "OpenSans-regular";
          font-size: 12px;
          line-height: 15px;
          margin: 0
        }
      }

      .noMarginLabel {
        margin: 0px;
      }

      .showMore {
        color: #1CA5D5;
        font-family: "OpenSans-bold";
        font-size: 12px;
        line-height: 28px;

        .showMore:hover {
          text-decoration: none
        }
      }

      .lunchDetail-basic {
        // width: 80%;
        width: 50%;
      }

      .showMoreText {
        max-width: 80%;
      }

      .lunchDetail-users {
        .guest-section {
          box-sizing: border-box;
          border: 1px solid #E0EAEE;
          background-color: #FFFFFF;
          padding: 17px 25px;
          margin-bottom: 16px;
          min-width: 417px;
          box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.04);

          .lunchDetail-label {
            color: #10111C;
            font-family: "OpenSans-regular";
            font-size: 12px;
            line-height: 15px;
            margin: 0
          }

          .guest-section-status {


            div {
              height: 15px;
              font-family: "OpenSans-regular";
              font-size: 12px;
              font-weight: 600;
              line-height: 15px;
              margin-left: 24px;
              display: inline-block
            }

            .confirmed {
              flex-grow: 1;
              color: #3BBA42;
              margin-left: 0;
            }

            .pending {
              flex-grow: 1;
              color: #DB5A1C;
            }

            .request {
              flex-grow: 1;
              color: #10111C;
            }
          }

          .viewall-users {
            margin-top: 40px;
            height: 15px;
            color: #1CA5D5;
            font-family: "OpenSans-regular";
            font-size: 12px;
            font-weight: 600;
            line-height: 15px;
            cursor: pointer;
          }
        }

        .host-section {
          box-sizing: border-box;
          border: 1px solid #E0EAEE;
          background-color: #FFFFFF;
          padding: 17px 25px;
          min-width: 417px;
          box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.04);


          .lunchDetail-label {
            color: #10111C;
            font-family: "OpenSans-regular";
            font-size: 12px;
            line-height: 15px;
            margin: 0
          }

          .host-name {
            color: #10111C;
            font-family: "OpenSans-semibold";
            font-size: 14px;
            line-height: 18px;
            margin-top: 10px;
          }

          .host-details {
            display: flex;
            margin-top: 7px;

            .separator {
              box-sizing: border-box;
              height: 15px;
              width: 2px;
              border: 1px solid #979797;
              margin: 0 5px;
            }

            .phone {
              height: 15px;
              color: #10111C;
              font-family: "OpenSans-regular";
              font-size: 12px;
              line-height: 15px;
            }

            .email {
              height: 15px;
              color: #10111C;
              font-family: "OpenSans-regular";
              font-size: 12px;
              line-height: 15px;
            }
          }

          .viewall-users {
            margin-top: 25px;
            color: #1CA5D5;
            font-family: "OpenSans-semibold";
            font-size: 12px;
            // font-weight: 600;
            line-height: 15px;
            cursor: pointer;
          }
        }
      }
    }

    &-guest {
      .tabs {
        background-color: #E6F3F7;
        padding-left: 30px;

        .tab {
          margin-bottom: 0px;
        }

        .tab-label {
          margin-top: 10px;
        }

        .tab-label.active {
          margin-bottom: 15px;
        }
      }
    }
  }

  .placeHolderWrapper {
    box-sizing: border-box;
    border: 1px solid #E0EAEE;
    background-color: #FFFFFF;
    box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.04);
    display: flex;
    margin: 33px 42px;


    .width-80 {
      box-sizing: border-box;
      border: 1px solid #E0EAEE;
      background-color: #FFFFFF;
      box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.04);
      width: 432px;
      margin: 33px 42px;
      padding: 30px
    }

    .width-20 {
      width: 60%;

      .rightPlaceholder {
        padding: 30px;

        height: 120px;
        width: 415px;
        box-sizing: border-box;
        border: 1px solid #E0EAEE;
        background-color: #FFFFFF;
        box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.04);
        margin: 33px 42px;
      }
    }
  }

  .placeHolder {
    background: #EDEDED;
    border-radius: 6px;
  }

  .imgPlaceHolder {
    height: 17px;
    width: 17px;
    border-radius: 50%;
  }

  .list-firstName {
    font-size: 11px;
    line-height: 15px;
    color: #4f5056;
    font-weight: normal;
    margin-left: 10px;
  }


  .firstList {
    width: 75% !important;
    margin-top: 3px;
    padding-left: 28px !important;

  }
}