.admin {
  width: 95%;
  margin: 0px 40px;

  .admin-table{
    .username{
      padding-left: 32px;
    }
  }
  .navBarText {
    font-family: 'OpenSans-regular';
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #4f5056;
  }

  &-subHeader {
    display: flex;
    justify-content: space-between;

    .label {
      opacity: 0.4;
      color: #10111c;
      font-family: 'OpenSans-regular';
      font-size: 10px;
      letter-spacing: 0.42px;
      line-height: 10px;
      margin-bottom: 4px;
      margin-top: 16px;
    }

    .title {
      align-self: center;
      font-size: 14px;
      font-family: 'Nobile';
    }

    .rightSectionSubHeadr {
      position: relative;
      display: flex;
      margin-bottom: 1rem;

      .iconRight {
        display: inline-block;
        margin: 1rem;
      }
    }

    .titleModal {
      font-size: 14px;
      font-family: 'Nobile';
    }

    .borderBottom {
      width: 25px;
      border: 1px solid #1ca5d5;
    }

    .modalEmail {
      width: 400px;
    }

    .marginTop {
      margin-top: 16px;
    }

    .modalNameInput {
      width: 195px;
    }

    .nameFields {
      display: flex;
      align-content: center;

      .marginTopLastName {
        margin-top: 30px;
      }
    }

    .actionButtonWrapper {
      display: flex;
      align-items: center;
      margin-top: 16px;
    }

    .cancelText {
      font-family: 'OpenSans-regular';
      font-size: 10px;
      line-height: 15px;
      color: #1ca5d5;
      margin-left: 1rem;
    }

    .widthSelect {
      width: 196px;
    }

    .buttonStyle {
      height: 40px;
      width: 110px;

      .InnerShadow {
        width: 100px;
        height: 40px;
        box-shadow: 0 10px 10px -10px rgba(68, 98, 203, 0);
      }
    }

    .buttonStyleSave {
      height: 40px;
      width: 80px;

      .InnerShadow {
        width: 80px;
        height: 40px;
        box-shadow: 0 10px 10px -10px rgba(68, 98, 203, 0);
      }

      .textStyle {
        font-size: 12px;
        // padding: 0 10px;
      }
    }
  }

  .Toastify {
    position: absolute;
  }

  .inputwrapper-lastname {
    margin-left: 8px;
  }

  .admin-breadcrumb {
    padding-top: 16px;
    padding-bottom: 16px;

    .admin-breadcrumb-dashboard {
      height: 10px;
      width: 56px;
      color: #1CA5D5;
      font-family: "OpenSans-semibold";
      font-size: 11px;
      line-height: 10px;
      margin-right: 5px;
      cursor: pointer;
    }

    .admin-breadcrumb-dropdown {
      height: 4px;
      width: 7px;
      transform: rotate(90deg);
      margin-right: 5px;
    }

    .admin-breadcrumb-admin {
      height: 10px;
      width: 60px;
      color: #4F5056;
      font-family: "OpenSans-regular";
      font-size: 11px;
      line-height: 10px;
      margin-right: 5px;
    }
  }

  .admin-table {
    .action-dialogue {
      &-disable {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        border-bottom: 1px solid #F0F0F0;
        cursor: pointer;

        img {
          height: 12px;
          width: 12px;
          margin-right: 10px;
        }

        span {
          height: 15px;
          width: 79px;
          color: #4F5056;
          font-family: "OpenSans-regular";
          font-size: 12px;
          line-height: 15px;
        }
      }

      &-delete {
        display: flex;
        align-items: center;
        height: 50px;
        justify-content: center;
        cursor: pointer;

        img {
          height: 12px;
          width: 12px;
          margin-right: 10px;
        }

        span {
          height: 15px;
          width: 79px;
          color: #4F5056;
          font-family: "OpenSans-regular";
          font-size: 12px;
          line-height: 15px;
        }
      }
    }
  }
}