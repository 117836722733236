@import "../../common-styles/colors.scss";
@import "../../common-styles/fonts.scss";

.card {
  display: flex;
  border-radius: 10px !important;
  border:none !important;
  &-title {
    opacity: 0.6;
    color: white;
    font-family: $OPEN_SANS;
    font-size: $TEN;
    font-weight: 600;
    letter-spacing: 0.42px;
    line-height: 10px;
    margin: 18px 74px 6px 20px;
    margin-bottom:0.3750rem !important;
  }
  &-name {
    color: #FFFFFF;
    font-family: $CIRCULAR_STD;
    font-size: $SIXTEEN;
    line-height: 17px;
    margin-left: 20px;
  }
  &-image{
    margin-top:12px;
    margin-right: 12px;
  }
  .flex-group{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
