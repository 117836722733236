
.side-drawer {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 461px;
  max-width: 461px;
  z-index: 5;
  transform: translateX(100vw);
  transition: transform 0.3s ease-out;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 1px 17px 0 rgba(0,0,0,0.09);

  &-content{
    display: flex;
    flex-direction: column;
    padding:38px 32px 38px 29px;
    overflow-y: scroll !important;
    height: 100%;
    width:461px;
  }
}

 .open-drawer {
  transform: translateX(calc(100vw - 461px));
}