@import "./fonts.scss";
@import "./colors.scss";
// common styles for listing components

.main {
  width: 95%;
  margin: 0px 40px;

  &-subHeader {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;

    .status {
      margin-left: 30px;
    }

    .title {
      align-self: center;
      font-family: $CIRCULAR_STD;
      margin-bottom: 24px;
      color: $BLACK;
      font-size: 18px;
      letter-spacing: 0.45px;
      line-height: 14px;
    }

    .rightSectionSubHeadr {
      display: flex;
      align-items: center;
      position: relative;
      margin-right: 41px;
      .errorText {
        font-size: 10px;
        font-family: "OpenSans-regular";
        color: red;
        margin-top: 12px;
      }

      .iconRight {
        display: inline-block;
        margin: 1rem;
      }

      .labelTop {
        margin-top: 27px;
        // margin-bottom: 4px
      }

      .cursorPointer {
        cursor: pointer;
      }

      .modalNameInput {
        width: 367px;
      }

      .displayFlex {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .width-50 {
        width: 50%;
      }

      .justifyAround {
        justify-content: normal;
      }

      .marginLeftTill {
        margin-left: 120px;
      }

      .tillTextLeft {
        margin-left: 4px;
      }

      .widthSelect {
        width: 79px;
        border-radius: 10px;
        // border: 1px solid red;
      }

      .titleModal {
        font-size: 14px;
        font-family: "OpenSans-bold";
        margin-bottom: 11px;
      }

      .label {
        opacity: 0.4;
        color: #10111c;
        font-family: "OpenSans-semibold";
        font-size: 10px;
        line-height: 18px;
      }

      .actionButtonWrapper {
        display: flex;
        align-items: center;

        .buttonStyleSave {
          height: 40px;
          width: 80px;

          .InnerShadow {
            width: 80px;
            height: 40px;
            box-shadow: 0 10px 10px -10px rgba(68, 98, 203, 0);
          }

          .textStyle {
            font-size: 12px;
            // padding: 0 10px;
          }
        }

        .cancelText {
          font-family: "OpenSans-regular";
          font-size: 10px;
          line-height: 15px;
          color: #1ca5d5;
          margin-left: 1rem;
        }
      }
    }
  }

  .displayFlex {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    // margin:16px 0 8px 0;
    .filterName {
      opacity: 0.3;
      color: #10111c;
      font-family: "OpenSans-regular";
      font-size: 10px;
      letter-spacing: 0.42px;
      line-height: 10px;
      margin-right: 8px;
      font-weight: bold;
    }

    .clearAll {
      color: #1ca5d5;
      font-family: "OpenSans-regular";
      font-size: 12px;
      line-height: 15px;
      font-weight: bold;
    }
  }

  .mTopFilters {
    margin-bottom: 10px;
  }

  .marginTopActionButton {
    margin-top: 32px;
  }

  .marginLeft {
    margin-right: 10px;
  }
}

.absolute {
  position: absolute;
}
