.tabWrapper {
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.1);
  .navBarText {
    font-family: 'OpenSans-regular';
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #4f5056;
    .activeDiv {
      width: 20px;
    }
  }
  .logoHeader {
    margin-right: 81px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
  }
  &-logout {
    width: 100%;
    display: flex;
    flex-direction: row-reverse !important;
    &--small {
      width: 150px;
    }
  }
  .settings-option{
    margin:0px 41px 0px 32px;
    cursor: pointer;
  }
}
