@import "../../common-styles/colors.scss";
@import "../../common-styles/fonts.scss";

.action-modal-open {
  background: rgba($color: #676b81, $alpha: 0.8);
  width: 100%;
  height: 110%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 5;

  .action-modal-child {
    position: relative;
    height: 394px;
    width: 461px;
    background-color: #ffffff;
    box-shadow: 0 1px 17px 0 rgba(0, 0, 0, 0.09);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .modal-heading {
      opacity: 0.4;
      color: #10111c;
      font-family: $OPEN_SANS_SEMI_BOLD;
      font-size: $TEN;
      letter-spacing: 0.42px;
      line-height: 10px;
      margin-bottom: 4px;
    }

    .modal-textbox {
      height: 40px;
      width: 400px;
      border-radius: 4px;
      border: none;
      background-color: #f9f9f9;
      padding: 12px 16px 13px 12px;
      outline: none;
      font-family: $OPEN_SANS;
    }

    .action-modal-heading {
      height: 44px;
      width: 317px;
      color: #10111c;
      font-family: $CIRCULAR_STD;
      font-size: 18px;
      letter-spacing: 0.45px;
      line-height: 22px;
      text-align: center;
      margin-top: 40px;
    }

    .action-modal-selection {
      margin-top: 32px;
      color: #10111c;
      font-family: $OPEN_SANS;
      font-size: $TWELVE;
      line-height: 15px;
      display: flex;
      justify-content: center;
      .action-modal-checkboxes {
        box-sizing: border-box;
        height: 16px;
        width: 16px;
        border: 1px solid #b1c6cd;
        border-radius: 3px;
        background-color: #ffffff;
      }
      .action-modal-checkbox {
        margin-right: 16px;
      }
    }

    .action-modal-textarea {
      margin-top: 16px;
      border: none;
      outline: none;
      background-color: #f9f9f9;
      padding: 10px;

      &::placeholder {
        opacity: 0.3;
        color: #555766;
        font-family: $OPEN_SANS;
        font-size: $TWELVE;
        line-height: 18px;
      }
    }

    .action-modal-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 24px;
      .go-toparticipant-listing{
        border: none;
        background-color: $CTA;
        height: 40px;
        color:white;
        border-radius: 5px;
        outline: none;
      }
      .action-modal-cancel-button {
        background: white;
        border: none;
        color: $CTA;
        margin-right: 42px;
        font-size: $TWELVE;
      }
      .InnerShadow {
        box-shadow: none;
      }
      .action-modal-forcefully-button{
        height: 40px;
        border-radius: 4px;
        background-color: #41bbe5;
        color: #ffffff;
        font-family: $OPEN_SANS_SEMI_BOLD;
        font-size: $FOURTEEN;
        line-height: 16px;
        border: none;
      }
      .action-modal-reject-button {
        height: 40px;
        width:90px;
        border-radius: 4px;
        background-color: #41bbe5;
        color: #ffffff;
        font-family: $OPEN_SANS_SEMI_BOLD;
        font-size: $FOURTEEN;
        line-height: 16px;
        border: none;
      }
      .action-modal-general-button {
        height: 40px;
        width:90px;
        border-radius: 4px;
        background-color: #41bbe5;
        color: #ffffff;
        font-family: $OPEN_SANS_SEMI_BOLD;
        font-size: $FOURTEEN;
        line-height: 16px;
        border: none;
      }
      .action-modal-draw-raffle-button {
        height: 40px;
        border-radius: 4px;
        background-color: #41bbe5;
        color: #ffffff;
        font-family: $OPEN_SANS_SEMI_BOLD;
        font-size: $FOURTEEN;
        line-height: 16px;
        border: none;
      }
      .action-modal-accept-button {
        height: 40px;
        width: 153px;
        border-radius: 4px;
        background-color: #41bbe5;
        color: #ffffff;
        font-family: $OPEN_SANS_SEMI_BOLD;
        font-size: $FOURTEEN;
        line-height: 16px;
        border: none;
      }
      .disabled {
        pointer-events: none;
        background-color: #cccc;
      }
      .hover {
        cursor: pointer;
      }
    }
  }
}

.action-modal-close {
  display: none;
}

.action-approve-modal {
  .action-modal-child {
    height: 200px;
    width: 461px;
  }
}

.mark-complete-modal {
  .action-modal-heading {
    margin-top: 56px !important;
    margin-bottom: 32px;
  }
  .action-modal-child {
    height: 314px;
    width: 461px;
  }
}

.mark-modal-open .cancel-modal-open {
  height: 127% !important;
}

.red-color {
  color: red;
  margin-top: 2px;
  font-size: 12px;
}

.cancel-modal-open {
  background: rgba($color: #676b81, $alpha: 0.8);
  width: 100%;
  height: 130%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 5;

  .cancel-modal-child {
    position: relative;
    height: 394px;
    width: 461px;
    background-color: #ffffff;
    box-shadow: 0 1px 17px 0 rgba(0, 0, 0, 0.09);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .modal-heading {
      opacity: 0.4;
      color: #10111c;
      font-family: $OPEN_SANS_SEMI_BOLD;
      font-size: $TEN;
      letter-spacing: 0.42px;
      line-height: 10px;
      margin-bottom: 4px;
    }

    .modal-textbox {
      height: 40px;
      width: 400px;
      border-radius: 4px;
      border: none;
      background-color: #f9f9f9;
      padding: 12px 16px 13px 12px;
      outline: none;
      font-family: $OPEN_SANS;
    }

    .pad {
      color: #4f5056;
      font-size: $FOURTEEN;
      line-height: 22px;
      padding:12px;
      font-family: $OPEN_SANS;
    }

    .bold{
      font-weight: 600;
    }

    .cancel-modal-heading {
      height: 33px;
      width: 317px;
      color: #10111c;
      font-family: $CIRCULAR_STD;
      font-size: 18px;
      letter-spacing: 0.45px;
      line-height: 22px;
      text-align: center;
      margin-top: 64px;
    }

    .modal-flex {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 20px;
      padding-right: 20px;
      text-align: center;
    }

    .action-modal-selection {
      margin-top: 32px;
      color: #10111c;
      font-family: $OPEN_SANS;
      font-size: $TWELVE;
      line-height: 15px;
      display: flex;
      justify-content: center;
      .action-modal-checkboxes {
        box-sizing: border-box;
        height: 16px;
        width: 16px;
        border: 1px solid #b1c6cd;
        border-radius: 3px;
        background-color: #ffffff;
      }
      .action-modal-checkbox {
        margin-right: 16px;
      }
    }

    .action-modal-textarea {
      margin-top: 16px;
      border: none;
      outline: none;
      background-color: #f9f9f9;
      padding: 10px;

      &::placeholder {
        opacity: 0.3;
        color: #555766;
        font-family: $OPEN_SANS;
        font-size: $TWELVE;
        line-height: 18px;
      }
    }

    .cancel-modal-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 24px;
      .cancel-modal-cancel-button {
        background: white;
        border: none;
        color: $CTA;
        margin-right: 42px;
        font-size: $TWELVE;
      }
      .InnerShadow {
        box-shadow: none;
      }
      .action-modal-reject-button {
        height: 40px;
        width: 90px;
        border-radius: 4px;
        background-color: #41bbe5;
        color: #ffffff;
        font-family: $OPEN_SANS_SEMI_BOLD;
        font-size: $FOURTEEN;
        line-height: 16px;
        border: none;
      }
      .action-modal-accept-button {
        height: 40px;
        width: 153px;
        border-radius: 4px;
        background-color: #41bbe5;
        color: #ffffff;
        font-family: $OPEN_SANS_SEMI_BOLD;
        font-size: $FOURTEEN;
        line-height: 16px;
        border: none;
      }
      .disabled {
        pointer-events: none;
        background-color: #cccc;
      }
      .hover {
        cursor: pointer;
      }
    }
  }
}

.action-modal-close {
  display: none;
}

.action-approve-modal {
  .action-modal-child {
    height: 200px;
    width: 461px;
  }
}

.mark-complete-modal {
  .action-modal-heading {
    margin-top: 56px !important;
    margin-bottom: 32px;
  }
  .action-modal-child {
    height: 314px;
    width: 461px;
  }
}

.z-index{
  z-index: 100;
  position: fixed;
}

.padding20{
  padding: 20px;
}
.margin-left-20{
  margin-left: 20px;
}

.edit-lunch-modal {
    .action-modal-child {
      height: 415px !important;
    }
    .action-modal-heading {
      height: auto !important;
    }
    .action-modal-checkbox{
      margin-left: 4px;
    }
}