.paginate {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;

  &-size {
    display: flex;
    height: 15px;
    color: #4f5056;
    font-family: 'OpenSans-regular';
    font-size: 12px;
    line-height: 15px;
    justify-content: space-evenly;
    /* align-items: center; */
    align-content: center;

    .size-text {
      color: #4F5056;
      font-family: "OpenSans-regular";
      font-size: 12px;
      margin-top: 3px;
    }
  }

  &-numbers {
    display: flex;

    .paginate-block {
      display: flex;
      justify-self: center;
      align-self: center;
      color: #1ca5d5;
      font-family: 'OpenSans-regular';
      font-size: 12px;
      font-weight: 600;
      line-height: 15px;
      margin-right: 16px;
      cursor: pointer;
    }

    .active {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 19px;
      width: 19px;
      border: 1px solid #38bae7;
      border-radius: 3px;
      background-color: #edfaff;
      box-shadow: 0 8px 12px 0 rgba(68, 98, 203, 0.35);
    }
  }

  .paginate__control {
    border: none;
    position: relative;
    top: -9px;
    background: transparent;
    padding-right: 5px;
  }

  .paginate__control--menu-is-open {
    box-shadow: none;
  }

  .paginateContainer {
    top: 0px;
  }

  .paginate__control--is-focused {
    border: none;
    box-shadow: none;
  }

  .paginate__single-value {
    color: #1ca5d5;
    font-family: 'OpenSans-bold';
    font-size: 12px;
    line-height: 15px;
  }

  .paginate__dropdown-indicator {
    color: #1ca5d5;
    padding: 0 4px;
    position: relative;
    top: 1px;

    svg {
      height: 12px;
      width: 12px;
    }
  }

  .paginate__dropdown-indicator:hover {
    color: #1ca5d5;
  }

  .css-bgvzuu-indicatorSeparator {
    display: none;
  }

  .paginate__value-container {
    padding: 0px 10px;
  }
}