@mixin loading-boxes($height, $width, $borderRadius, $bg, $marginBottom) {
  height: $height;
  width: $width;
  border-radius: $borderRadius;
  background-color: $bg;
  margin-bottom: $marginBottom;
}
.loading-screen {
  padding: 33px 0px 0px 42px;
  &-title {
  }
  &-tabs {
  }
  &-overview-card {
    .lunch-title {
      @include loading-boxes(34px, 200px, 6px, #eff3f6, 22px);
    }
    .to-benefit {
      @include loading-boxes(34px, 494px, 6px, #eff3f6, 22px);
    }
    .raffle-amount {
      @include loading-boxes(33px, 163px, 6px, #eff3f6, 42px);
    }
    .raffle-closes-on {
      @include loading-boxes(34px, 247px, 6px, #eff3f6, 22px);
    }
    .info-cards {
      display: flex;
      .charity-card {
        @include loading-boxes(104px, 194px, 6px, #eff3f6, 22px);
        margin-right: 9px;
      }
      .celebrity-card {
        @include loading-boxes(104px, 194px, 6px, #eff3f6, 22px);
      }
    }
  }
  &-host-card {
    display: flex;
    .left-container {
      @include loading-boxes(384px, 278px, 6px, #eff3f6, 22px);
      margin-right: 28px;
    }
    .right-container {
      .location {
        @include loading-boxes(34px, 166px, 6px, #eff3f6, 22px);
      }
      .bio {
        @include loading-boxes(34px, 494px, 6px, #eff3f6, 22px);
      }
      .location {
        display: flex;
        .tab-left {
          @include loading-boxes(33px, 52px, 6px, #eff3f6, 22px);
        }
        .tab-right {
          @include loading-boxes(33px, 106px, 6px, #eff3f6, 22px);
        }
      }
      .lunch-location {
        display: flex;
        .tab-left {
          @include loading-boxes(34px, 79px, 6px, #eff3f6, 22px);
        }
        .tab-right {
          @include loading-boxes(34px, 79px, 6px, #eff3f6, 22px);
        }
      }
      .hobbies {
        display: flex;
        .tab-left {
          @include loading-boxes(34px, 79px, 6px, #eff3f6, 22px);
        }
        .tab-right {
          @include loading-boxes(34px, 79px, 6px, #eff3f6, 22px);
        }
      }
      .interest {
        @include loading-boxes(34px, 166px, 6px, #eff3f6, 22px);
      }
    }
  }

  &-charity-card {
    .charity-name {
      .title {
        @include loading-boxes(14px, 166px, 6px, #eff3f6, 8px);
      }
      .name {
        @include loading-boxes(20px, 348px, 6px, #eff3f6, 22px);
      }
    }
    .about {
      .title {
        @include loading-boxes(14px, 166px, 6px, #eff3f6, 8px);
      }
      .name {
        @include loading-boxes(34px, 348px, 6px, #eff3f6, 22px);
      }
    }
    .ein {
      .title {
        @include loading-boxes(14px, 166px, 6px, #eff3f6, 8px);
      }
      .name {
        @include loading-boxes(14px, 100px, 6px, #eff3f6, 22px);
      }
    }
    .url {
      .title {
        @include loading-boxes(14px, 54px, 6px, #eff3f6, 8px);
      }
      .name {
        @include loading-boxes(14px, 300px, 6px, #eff3f6, 22px);
      }
    }
    .contact-details {
      .title {
        @include loading-boxes(34px, 200px, 6px, #eff3f6, 8px);
      }
      .name {
        @include loading-boxes(18px, 64px, 6px, #eff3f6, 22px);
      }
    }
  }

  &-celebrity-card {
    .celebrity-name {
      .title {
        @include loading-boxes(14px, 166px, 6px, #eff3f6, 8px);
      }
      .name {
        @include loading-boxes(24px, 166px, 6px, #eff3f6, 22px);
      }
    }
    .about {
      .title {
        @include loading-boxes(14px, 166px, 6px, #eff3f6, 8px);
      }
      .name {
        @include loading-boxes(34px, 348px, 6px, #eff3f6, 22px);
      }
    }
    .contact-details {
      border-radius: 10px;
      height: 122px;
      width: 616px;
      border: 1px solid #f0eeee;
      box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.04);
      margin-bottom: 22px;
      background-color: #eff3f6;
    }
  }

  &-notes-card-empty {
    @include loading-boxes(34px, 348px, 6px, #eff3f6, 8px);
  }
  &-notes-card {
    margin-bottom: 22px;
    .list-row {
      .date {
        display: flex;
        .day {
          @include loading-boxes(14px, 64px, 6px, #eff3f6, 8px);
          margin-right: 3px;
        }
        .dot {
          border-radius: 50%;
          background-color: #eff3f6;
          margin-right: 3px;
          height: 5px;
          width: 5px;
        }
        .time {
          @include loading-boxes(14px, 40px, 6px, #eff3f6, 8px);
        }
      }
      .description {
        @include loading-boxes(34px, 348px, 6px, #eff3f6, 8px);
      }
      .user {
        display: flex;
        .image {
          border-radius: 50%;
          background-color: #eff3f6;
          height: 17px;
          width: 17px;
          margin-right:8px;
        }
        .name {
          @include loading-boxes(14px, 100px, 6px, #eff3f6, 8px);
        }
      }
    }
  }

  &-participant-and-contributor-listing{
    .total-count{
      height:58px;
      background-color: #eff3f6;
      box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.04);
      padding-left: 28px;
      margin-bottom: 24px;
      border-radius: 10px;
    }
    .list-item{
      padding-left: 55px;
      height: 50px;
      width:90%;
      margin-bottom: 10px;
      background-color: #eff3f6;
      border-radius: 10px;
    }
  }
}
