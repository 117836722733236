// fonts for different screens
$OPEN_SANS:"OpenSans-regular";
$CIRCULAR_STD:"Circular-std-bold";
$NOBILE:"Nobile";
$OPEN_SANS_SEMI_BOLD:"OpenSans-semibold";
$OPEN_SANS_BOLD: "OpenSans-bold";

// diffenent sizes for fonts
$TEN:10px;
$TWELVE:12px;
$FOURTEEN:14px;
$SIXTEEN:16px;
$EIGHTEEN:18px;