@import '../../common-styles/colors.scss';

.modalWrapper {
  // height: 368px;
  background: #fff;
  width: 461px;
  position: absolute;
  right: 0;
  top: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 24px;
  
  .cancel{
    border:none;
    color: $CTA;
    padding:0;
    font-size: 12px;
  }

  .cancel:hover{
    text-decoration: underline $CTA;
  }

  .filter-buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top:32px;
    .CTAWrapper.apply-button{
      width: 80px;
      height:40px;
      margin-right:18px;
      font-size:14px;
      .InnerShadow{
        width:80px;
        height:39px;
      }
    }
  }
}

.user-alert {
  position: absolute;
  padding-top: 15%;
  padding-left: 35%;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.3);
  .alert-modal {
    width: 461px;
    border-radius: 4px;	
    background-color: #FFFFFF;	
    box-shadow: 0 1px 17px 0 rgba(0,0,0,0.09);
    &-title {
      padding-top: 64px;
      color: #10111C;	font-family: Nobile;	font-size: 16px;	font-weight: bold;	letter-spacing: 0.4px;	line-height: 14px;	text-align: center;
    }
    &-description {
      margin-top: 10px;
      color: #4F5056;	font-family: "OpenSans-regular";	font-size: 14px;	line-height: 22px;	text-align: center;
    }
    &-action {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0px;
      &-cancel {
        margin-right: 50px;
        color: #1CA5D5;	font-family: "OpenSans-regular";	font-size: 12px;	font-weight: 600;	line-height: 15px;
        cursor: pointer;
      }
      &-confirm {
        padding: 12px 16px;
        border-radius: 4px;	
        background-color: #41BBE5;	
        box-shadow: 0 8px 12px 0 rgba(68,98,203,0.35);
        color: #FFFFFF;
        font-family: "OpenSans-regular";	font-size: 14px;	font-weight: 600;	line-height: 16px;
        cursor: pointer;
      }
    }
  }
}
