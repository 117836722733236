.custom-breadcrumb {
  padding-top: 22px;
  padding-bottom: 12px;
  margin-right:40px;
  &-from {
    color: #1ca5d5;
    font-family: "OpenSans-semibold";
    line-height: 10px;
    margin-right: 5px;
    cursor: pointer;
    font-size: 10px;
  }

  &-dropdown {
    height: 4px;
    width: 7px;
    transform: rotate(90deg);
    margin-right: 5px;
  }

  &-to {
    color: #4f5056;
    font-family: "OpenSans-regular";
    line-height: 10px;
    margin-right: 5px;
    font-size: 10px;
  }
}
