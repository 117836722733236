.inputWrapper {
  .label {
    opacity: 0.4;
    color: #10111c;
    font-family: 'OpenSans-regular';
    font-size: 10px;
    letter-spacing: 0.42px;
    line-height: 10px;
    margin-bottom: 4px;
  }

  .textInput {
    border-radius: 4px;
    padding-left: 16px;
    height: 40px;
    width: 327px;
    border-radius: 4px;
    // border: transparent;
  }

  ::-webkit-input-placeholder {
    opacity: 0.2;
    color: #555766;
    font-family: 'OpenSans-regular';
    font-size: 12px;
    line-height: 18px;
  }

  .errorText {
    color: #cd4242;
    font-family: 'OpenSans-regular';
    font-size: 12px;
    line-height: 12px;
    margin-top: 7px;
  }

  .iserrorborder {
    border: 1px solid #dda7a7;
    background: #fff8f8;
  }

  .notError {
    border: none;
    background-color: #f9f9f9;
  }

  .isWidth {
    width: 22px;
  }
}