.spinner {
  display: flex;

  .spin-loader {
    border: 2px solid #f3f3f3; /* Light grey */
    border-top: 2px solid black; /* Blue */
    border-radius: 50%;
    width: 12px;
    height: 12px;
    animation: spin 2s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
    margin-right: 3px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
