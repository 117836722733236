.dropDownContainer {
  .label {
    opacity: 0.4;
    color: #10111c;
    font-family: "OpenSans-regular";
    font-size: 10px;
    letter-spacing: 0.42px;
    line-height: 10px;
    margin-bottom: 4px;
  }
  .dropDown__control {
    background-color: #f9f9f9;
    border: 0;
    box-shadow: none;
  }
  .dropDown__value-container {
    font-family: "OpenSans-regular";
    font-size: 12px;
    line-height: 18px;
  }
  .dropDown__placeholder {
    opacity: 0.2;
    color: #555766;
    font-family: "OpenSans-regular";
    font-size: 12px;
    line-height: 18px;
  }
  .dropDown__indicator-separator {
    display: none;
  }
  .dropDown__dropdown-indicator {
    color: #1ca5d5;
    svg {
      height: 12px;
      width: 20px;
    }
  }
}
.iserrorborder {
  border: 1px solid #dda7a7;
  border-radius: 4px;
  .dropDown__control {
    background-color: #fff8f8;
  }
}
.notError {
  border: none;
  background-color: #f9f9f9;
}
.causeBorder {
  border: 1px solid #38bae7;
  background-color: #edfaff;
  border-radius: 4px;
}
.inputWrapper{
  position: relative;
  padding-bottom: 7px;
  .errorText{
    position: absolute;
    width: 170px;
  }

  .drop-down-error{
    position: absolute;
    top:-45px;
    left:-97px;
    width:200px;
    font-size: 12px;
    color:red;
  }
}
