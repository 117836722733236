// TODO Move sizes and colors to separte varibale file
// TODO Use BEM pattern

.wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .m-top {
    margin-top: 16px;
  }
  .marginTop {
    margin-top: 24px;
  }
  .forgotText {
    color: #1ca5d5;
    font-family: 'OpenSans-regular';
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    text-align: right;
    margin: 16px 0;
    display: block;
  }
  .SignInStyle {
    height: 44px;
    width: 327px;
  }
}
