.inputWrapperCode {
  .label {
    opacity: 0.4;
    color: #10111c;
    font-family: 'OpenSans-regular';
    font-size: 10px;
    letter-spacing: 0.42px;
    line-height: 10px;
    margin-bottom: 4px;
  }

  .textInput {
    height: 54px;
    width: 48px;
    border-radius: 4px;
    text-align: center;
    margin-left: 7px;
  }
  .errorText {
    color: #cd4242;
    font-family: 'OpenSans-regular';
    font-size: 12px;
    line-height: 12px;
    margin-top: 7px;
  }
  .iserrorborder {
    border: 1px solid #dda7a7;
    background: #fff8f8;
  }
  .notError {
    border: none;
    background-color: #f9f9f9;
  }
}
div.codecontent:first-child {
  margin-left: 0px;
}
