@import '../../common-styles/fonts.scss';

.list-button {
  height: 40px;
  width: 96px;
  border-radius: 4px;
  background-color: #41bbe5;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  &-text {
    color: #ffffff;
    font-family: $OPEN_SANS_SEMI_BOLD;
    font-size: 14px;
    line-height: 16px;
  }
}
