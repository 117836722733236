.bubbleBottom {
  position: absolute;
  bottom: 0;
  left: 0;
}
.bubbleTop {
  position: absolute;
  top: 0;
  right: 0;
}
