@import "../../common-styles/fonts.scss";
@import "../../common-styles/colors.scss";

.cancel-button {
  background: white;
  border: none;
  color: $CTA;
  font-size: $TWELVE;
  font-family: $OPEN_SANS_SEMI_BOLD;
}

.save-button {
  height: 40px;
  width: 80px;
  border-radius: 4px;
  background-color:$CTA;
  color: #ffffff;
  font-family: $OPEN_SANS_SEMI_BOLD;
  font-size: $FOURTEEN;
  line-height: 16px;
  border: none;
}

.button-flex{
  display: flex;
  justify-content: center;
}