@import "../../common-styles/colors.scss";
@import "../../common-styles/fonts.scss";

.contact-detail-box {
  box-sizing: border-box;
  height: 122px;
  width: 616px;
  border: 1px solid #f0eeee;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.04);
  padding-top: 20px;
  padding-left: 24px;
  padding-bottom: 24px;
  padding-right: 24px;

  .contact-detail-header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .contact-title {
      height: 18px;
      color: $BLACK;
      font-family: $OPEN_SANS_SEMI_BOLD;
      font-size: 14px;
      line-height: 18px;
    }
    .contact-image {
      height: 14px;
      width: 12px;
      margin-right: 8px;
    }
  }

  .inline-details {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    .contact-detail-body {
      margin-left: 34px;
      .detail-text-format {
        height: 15px;
        color: $BLACK;
        font-family: $OPEN_SANS;
        font-size: $TWELVE;
        line-height: 15px;
      }

      .grey-text {
        opacity: 0.6;
        color: $BLACK;
        font-family: $OPEN_SANS_SEMI_BOLD;
        font-size: $TEN;
        line-height: 10px;
        letter-spacing: 0.42px;
      }
      .detail-margin {
        margin-top: 8px;
      }
    }
  }

  .contact-detail-body-first {
    .detail-text-format {
      height: 15px;
      color: $BLACK;
      font-family: $OPEN_SANS;
      font-size: $TWELVE;
      line-height: 15px;
    }

    .grey-text {
      opacity: 0.6;
      color: $BLACK;
      font-family: $OPEN_SANS_SEMI_BOLD;
      font-size: $TEN;
      line-height: 10px;
      letter-spacing: 0.42px;
    }
    .detail-margin {
      margin-top: 8px;
    }
  }
}
