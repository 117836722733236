.dashboard {
  background: #F3F3F3;
  height: 100vh;
  section {
    display: flex;
    .super-admin {
      flex-grow: 1;
      width: 20%;
      margin: 20px 40px 10px 20px;
      .admin-header {
        display: flex;
        padding: 24px;
        .heading {
          flex-grow: 1;
          height: 14px;
          width: 140px;	
          color: #10111C;	
          font-family: Nobile;	
          font-size: 16px;	
          font-weight: bold;	
          letter-spacing: 0.4px;	
          line-height: 14px;
        }
        .viewall {
          flex-grow: 1;
          height: 15px;	
          width: 45px;	
          color: #1CA5D5;	
          font-family: "OpenSans-regular";	
          font-size: 12px;	
          font-weight: 600;	
          line-height: 15px;	
          text-align: right;
          cursor: pointer;
        }
      }
      .super-admin-list {
        padding: 0px 24px;
        .super-admin-listItem {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #F0F0F0;
          margin-bottom: 10px;
          .super-admin-listItem-user {
            display: flex;
            flex-direction: column;
            height: 40px;
            .super-admin-listItem-user-name {
              height: 15px;
              color: #10111C;	
              font-family: "OpenSans-regular";	
              font-size: 12px;	
              font-weight: 600;	
              line-height: 15px;
              margin-bottom: 2px;
            }
            .super-admin-listItem-user-type {
              height: 15px;	
              color: #4F5056;	
              font-family: "OpenSans-regular";	
              font-size: 12px;	
              line-height: 15px;	
            }
          }
          .super-admin-listItem-status {
            height: 15px;
            color: #4F5056;	
            font-family: "OpenSans-regular";	
            font-size: 12px;	
            line-height: 15px;	
            text-align: center;
            .dot {
              height: 7px;
              width: 7px;
              box-shadow: 0 0 8px 0 rgba(109,255,116,0.4);
              border-radius: 50%;
              display: inline-block;
              margin-right: 10px;
            }
            .active {
              background-color: #65BE6A;	
            }
            .inactive {
                background-color: #AFB0AF;
            }
          }
        }
        .super-admin-listItem:last-child {
          border: none;
        }
      }
    }
    .lunches {
      flex-grow: 1;
    }
    .host-guest {
      flex-grow: 3;
      width: 80%;
    }
    .ticket {
      flex-grow: 3;
      margin: 20px 40px 10px 20px;
    }
  }
  .ticket {
    visibility: hidden;
  }
}