@import "../../common-styles/common.scss";
@import "../../common-styles/colors.scss";
@import "../../common-styles/fonts.scss";

.charities {
  &-header-section {
    display: flex;
    justify-content: space-between;
    .main-subHeader .title {
      margin-bottom: 12px;
    }
    .searchAndFilter {
      display: flex;
      align-items: center;
      .filter-image {
        margin-left: 40px;
        margin-top: 18px;
      }
      .filter-search-image {
        margin-top: 18px;
      }
    }
  }
  .list-header{
    .charities-ein-no{
      width: 15%;
    }
    .charities-name{
      width: 30%;
    }
    .charities-url{
      width:30%;
    }
  }
  .list-body {
    .all-charities{
      .charities-ein-no{
        width: 15%;
      }
      .charities-name {
        font-weight: bold;
        width:30%;
      }
      .charities-url{
        width: 30%;
      }
    }
  }
  .paginate {
    display: flex;
    justify-content: flex-start;
    .active {
      color: #4f5056;
    }
    .paginate-size {
      margin-right: 40px;
    }
    .paginate-block {
      padding: 1px 4px 1px 4px;
      background-color: $LIGHT_BLUE;
    }
  }
}
