@import "../../common-styles/common.scss";
@import "../../common-styles/colors.scss";
@import "../../common-styles/fonts.scss";

.causes {
  .cause-header {
    font-family: $OPEN_SANS;
  }
  .clearAll {
    color: #1ca5d5;
    font-family: "OpenSans-regular";
    font-size: 12px;
    line-height: 15px;
    font-weight: bold;
  }
  .filterNames{
    padding: 0px 0px 5px 0px;
  }
  .filterAndTabsArea {
    display: flex;
    justify-content: space-between;
    height: 22px;
    margin-bottom: 12px;
    .tabs {
      .tab {
        .tab-border.active {
          width: 8px;
          height: 1px;
        }
      }
    }
    .rightSectionSubHeadr {
      display: flex;

      .modalWrapper {
        top: auto;
        right: 24px;
        margin-top: 20px;
      }
      .titleModal {
        font-size: 14px;
        font-family: "OpenSans-bold";
        margin-bottom: 11px;
      }

      .label {
        opacity: 0.4;
        color: #10111c;
        font-family: "OpenSans-semibold";
        font-size: 10px;
        line-height: 18px;
      }
      .nameFields {
        input {
          border-radius: 4px;
          height: 40px;
          width: 367px;
          border: none;
          background-color:#f9f9f9;
          margin-bottom: 10px;
          padding-left:10px;
        }
      }
      .filter-modal-buttons {
        display: flex;
        .apply-button {
          height: 40px;
          width: 80px;
          background-color: $CTA;
          color: white;
          border: none;
          border-radius: 4px;
          font-size: 12px;
          font-family: $OPEN_SANS;
          box-shadow: 0 10px 10px -10px rgba(68, 98, 203, 0);
        }
        .disabled{
          background-color: rgb(65, 62, 62);
          opacity: 0.6;
          pointer-events: none;
        }
        .cancel-button {
          border: none;
          color: $CTA;
        }
      }
    }
  }
  .filter-search-image {
    height: 15px;
    width: 15px;
    margin-right: 40px;
  }
  .filter-image {
    height: 14.25px;
    width: 19px;
    cursor: pointer;
  }
  // .filter-search-image,
  // .filter-image {
  // cursor: pointer;
  // background-color: white;
  // }
  .switch-button {
    margin: 41px;
  }
  .review-button {
    width: 80px;
    height: 40px;
    font-size: 14px;
  }
  .list-header {
    .cause-id {
      width: 32%;
      margin-right: 0.5em;
    }
    .cause-name {
      padding-left: 3.3%;
      width: 180% !important;
      margin-right: 3em;
    }
    .cause-start-date {
      padding-left: 5px;
      margin-right: 3em;
    }
    .cause-host-name {
      padding-left: 2px;
      margin-right: 1em;
    }
    .cause-charity {
      padding-left: 0px;
      margin-right: 3em;
    }
    .cause-celebrity {
      padding-left: 0px;
      margin-right: 3em;
    }
    .cause-status {
      padding-left: 3px;
      margin-right: 2em;
    }
    .cause-action {
      width: 40%;
    }
  }
  .list-row {
    .cause-id {
      width: 63%;
      margin-right: 0.5em;
    }
    .cause-title {
      margin-right: 3em;
      width: 180% !important;
      font-weight: 600;
      color: #4f5056;
    }
    .draw-date-time {
      margin-right: 3em;
    }
    .end-date-time {
      margin-right: 3em;
    }
    .hostname {
      margin-right: 1em;
    }
    .charity {
      margin-right: 3em;
    }
    .celebrity {
      margin-right: 3em;
    }
    .status {
      margin-right: 2em;

      .message-image {
        margin-left: 16px;
      }
    }
    .action-button {
      width: 40%;
    }
  }
  .published-table {
    .list-header {
      .cause-name {
        padding-left: 2.5%;
      }
    }
    .all-published {
      .cause-id {
        width: 50%;
      }

      .amount-collected {
        div {
          width: 102px;
          text-align: end;
        }
      }
    }
  }

  .completed-table {
    .list-header {
      .cause-name {
        padding-left: 3.2%;
      }
      .cause-amount-sent-to-charity {
        margin-right: 3em;
      }
    }
    .list-body {
      .cause-id {
        margin: 0px;
      }
      .amount-collected {
        div {
          width: 95px;
          text-align: end;
        }
      }
      .amount-sent-to-charity {
        margin-right: 2.5em;
        div {
          width: 127px;
          text-align: end;
        }
      }
    }
  }
  .raffle-drawn-table {
    .list-header {
      .cause-name {
        padding-left: 3.7%;
      }
    }
    .raffle-drawn {
      .amount-collected {
        div {
          width: 98px;
          text-align: end;
        }
      }
    }
  }
  .cancelled-table {
    .list-header {
      .cause-name {
        padding-left: 3.9%;
      }

      .cause-celebrity-name {
        margin-right: 36px;
      }
    }
  }
  .paginate {
    display: flex;
    justify-content: flex-start;
    .active {
      color: #4f5056;
    }
    .paginate-size {
      margin-right: 40px;
    }
    .paginate-block {
      padding: 1px 4px 1px 4px;
      background-color: $LIGHT_BLUE;
    }
  }
}

.raffle-modal-no-button {
  width: 100px;
}
